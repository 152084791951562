var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "my-6"
  }, [_vm.loading ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "card, list"
      }
    })], 1);
  }), 1)], 1) : !_vm.loading && !_vm.integrationsList.length ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_vm.teamIntegrationList.length ? _vm._l(_vm.teamIntegrationList, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-3",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goToDetailPage(item);
        }
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {}, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [item !== null && item !== void 0 && item.image ? _c('v-img', {
      staticClass: "mb-2",
      attrs: {
        "height": "40",
        "contain": "",
        "width": "40",
        "src": "/media/integrations/".concat(item === null || item === void 0 ? void 0 : item.image)
      }
    }) : _vm._e()], 1), _c('v-col', [_c('div', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " "), _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.showIntegrationHelp(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "textContent": _vm._s('mdi-help-circle-outline')
      }
    })], 1)], 1), item.status !== 2 ? _c('div', {
      staticClass: "cursor-pointer"
    }, [_c('v-icon', {
      attrs: {
        "color": item.connected ? 'success' : 'error'
      },
      domProps: {
        "textContent": _vm._s('mdi-circle-medium')
      }
    }), _vm._v(" " + _vm._s(item.connected ? "Connected" : "Connect") + " ")], 1) : _c('div', [_c('span', {
      staticClass: "cursor-pointer text-decoration-underline"
    }, [_c('v-icon', {
      attrs: {
        "color": "info"
      },
      domProps: {
        "textContent": _vm._s('mdi-circle-medium')
      }
    }), _vm._v(" Vote ")], 1)])])], 1)], 1), item.status !== 2 ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "icon": "",
        "color": "primary"
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s('mdi-arrow-right-bold-outline')
      }
    })], 1)], 1) : _vm._e()], 1)], 1)], 1);
  }) : _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "first-text": "No integrations connected to this app",
      "second-text": _vm.errorMessage
    }
  })], 1)], 2)], 1) : _vm._e(), _vm._l(_vm.integrationsList, function (item, index) {
    var _item$integration, _item$integration2, _item$integration3, _item$source, _item$source2;
    return _c('v-col', {
      key: index,
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "pa-3",
      attrs: {
        "outlined": ""
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {}, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [item !== null && item !== void 0 && (_item$integration = item.integration) !== null && _item$integration !== void 0 && _item$integration.image ? _c('v-img', {
      staticClass: "mb-2",
      attrs: {
        "height": "40",
        "contain": "",
        "width": "40",
        "src": "/media/integrations/".concat(item === null || item === void 0 ? void 0 : (_item$integration2 = item.integration) === null || _item$integration2 === void 0 ? void 0 : _item$integration2.image)
      }
    }) : _vm._e()], 1), _c('v-col', [_c('div', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$integration3 = item.integration) === null || _item$integration3 === void 0 ? void 0 : _item$integration3.name) + " "), _c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.showIntegrationHelp(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      },
      domProps: {
        "textContent": _vm._s('mdi-help-circle-outline')
      }
    })], 1)], 1), item.status !== 2 ? _c('div', {
      staticClass: "cursor-pointer"
    }, [_c('v-icon', {
      attrs: {
        "color": item.connected ? 'success' : 'error'
      },
      domProps: {
        "textContent": _vm._s('mdi-circle-medium')
      }
    }), _vm._v(" " + _vm._s(item.connected ? "Connected" : "Connect") + " ")], 1) : _vm._e(), item.status === 2 ? _c('div', [_c('span', {
      staticClass: "cursor-pointer text-decoration-underline",
      on: {
        "click": function click($event) {
          return _vm.notifyWhenAvailable(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "info"
      },
      domProps: {
        "textContent": _vm._s('mdi-circle-medium')
      }
    }), _vm._v(" Vote ")], 1)]) : _vm._e()])], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [item.status !== 2 && !item.connected ? _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark
      },
      on: {
        "click": function click($event) {
          var _item$integration4;
          $event.stopPropagation();
          return _vm.$router.push({
            name: 'integration-source',
            params: {
              integration_id: item === null || item === void 0 ? void 0 : (_item$integration4 = item.integration) === null || _item$integration4 === void 0 ? void 0 : _item$integration4.id
            }
          });
        }
      }
    }, [_vm._v(" Connect ")]) : _vm._e(), item !== null && item !== void 0 && (_item$source = item.source) !== null && _item$source !== void 0 && _item$source.name ? _c('v-chip', {
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$source2 = item.source) === null || _item$source2 === void 0 ? void 0 : _item$source2.name) || "Configure") + " ")]) : _vm._e(), item !== null && item !== void 0 && item.name ? _c('v-chip', {
      staticClass: "mr-1"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.name) + " ")]) : _vm._e(), item.status !== 2 && item.connected ? _c('v-btn', {
      staticClass: "ml-2",
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.openRemoveModal(item);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v("mdi-delete")])], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "mb-8 darkGrey--text"
  }, [_c('v-icon', {
    attrs: {
      "color": "grey",
      "left": "",
      "small": ""
    }
  }, [_vm._v("mdi-help-circle")]), _c('span', [_vm._v("We missed an integration that you need?")]), _c('span', {
    staticClass: "ml-1 primary--text text-decoration-underline cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.openIntercomChat(_vm.contactMessage);
      }
    }
  }, [_vm._v("Let us know!")])], 1)]), _c('Modal', {
    on: {
      "close": function close($event) {
        _vm.showUninstallModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        var _vm$selectedIntegrati, _vm$selectedIntegrati2, _vm$selectedIntegrati3, _vm$selectedIntegrati4, _vm$selectedIntegrati5, _vm$selectedIntegrati6, _vm$selectedIntegrati7;
        return [_c('v-card', [_c('v-card-title', [_vm._v(" Remove " + _vm._s((_vm$selectedIntegrati = _vm.selectedIntegration) === null || _vm$selectedIntegrati === void 0 ? void 0 : (_vm$selectedIntegrati2 = _vm$selectedIntegrati.integration) === null || _vm$selectedIntegrati2 === void 0 ? void 0 : _vm$selectedIntegrati2.name) + " ")]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
          staticClass: "font-16"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm._v(" Are you sure you want to disconnect " + _vm._s((_vm$selectedIntegrati3 = _vm.selectedIntegration) === null || _vm$selectedIntegrati3 === void 0 ? void 0 : (_vm$selectedIntegrati4 = _vm$selectedIntegrati3.integration) === null || _vm$selectedIntegrati4 === void 0 ? void 0 : _vm$selectedIntegrati4.name) + " from this app? "), _c('div', {
          staticClass: "my-5"
        }, [_c('div', {
          staticClass: "my-2"
        }, [_vm._v("Integration information")]), _c('ul', {
          staticClass: "mb-2"
        }, [_c('li', [_c('b', [_vm._v("Workspace: ")]), _vm._v(_vm._s((_vm$selectedIntegrati5 = _vm.selectedIntegration) === null || _vm$selectedIntegrati5 === void 0 ? void 0 : (_vm$selectedIntegrati6 = _vm$selectedIntegrati5.source) === null || _vm$selectedIntegrati6 === void 0 ? void 0 : _vm$selectedIntegrati6.name) + " ")]), _c('li', [_c('b', [_vm._v("Channel: ")]), _vm._v(_vm._s((_vm$selectedIntegrati7 = _vm.selectedIntegration) === null || _vm$selectedIntegrati7 === void 0 ? void 0 : _vm$selectedIntegrati7.name))])])]), _c('div', {
          staticClass: "my-5"
        }, [_c('i', [_vm._v("You can always connect it again.")])])])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "text": "",
            "disabled": _vm.loadingConfirm,
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showUninstallModal = false;
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "outlined": _vm.$vuetify.theme.dark,
            "color": "error",
            "loading": _vm.loadingConfirm
          },
          on: {
            "click": _vm.confirmUnistall
          }
        }, [_vm._v(" Remove ")])], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showUninstallModal,
      callback: function callback($$v) {
        _vm.showUninstallModal = $$v;
      },
      expression: "showUninstallModal"
    }
  }), _c('Modal', {
    attrs: {
      "closeable": ""
    },
    on: {
      "close": function close($event) {
        _vm.integrationHelpModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "card",
      fn: function fn() {
        return [_c('v-card', {
          staticClass: "pa-3"
        }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
          staticClass: "bodyFont",
          attrs: {
            "cols": "12"
          },
          domProps: {
            "innerHTML": _vm._s(_vm.integrationHelpDescription)
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.integrationHelpModal,
      callback: function callback($$v) {
        _vm.integrationHelpModal = $$v;
      },
      expression: "integrationHelpModal"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }