<template>
  <v-row class="my-6">
    <v-col v-if="loading" cols="12">
      <v-row>
        <v-col v-for="i in 4" :key="i" cols="12">
          <v-skeleton-loader type="card, list"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else-if="!loading && !integrationsList.length">
      <v-row>
        <template v-if="teamIntegrationList.length">
          <v-col
            v-for="(item, index) in teamIntegrationList"
            :key="index"
            class="py-0"
            cols="12"
          >
            <v-card @click="goToDetailPage(item)" outlined class="pa-3">
              <v-row align="center">
                <v-col class="">
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-img
                        class="mb-2"
                        height="40"
                        contain
                        width="40"
                        v-if="item?.image"
                        :src="`/media/integrations/${item?.image}`"
                      ></v-img>
                    </v-col>
                    <v-col>
                      <div>
                        {{ item?.name }}
                        <v-btn
                          @click.stop="showIntegrationHelp(item)"
                          icon
                          small
                        >
                          <v-icon
                            small
                            v-text="'mdi-help-circle-outline'"
                          ></v-icon>
                        </v-btn>
                      </div>
                      <div v-if="item.status !== 2" class="cursor-pointer">
                        <v-icon
                          :color="item.connected ? 'success' : 'error'"
                          v-text="'mdi-circle-medium'"
                        ></v-icon>
                        {{ item.connected ? "Connected" : "Connect" }}
                      </div>
                      <div v-else>
                        <span class="cursor-pointer text-decoration-underline">
                          <v-icon
                            color="info"
                            v-text="'mdi-circle-medium'"
                          ></v-icon>
                          Vote
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="item.status !== 2" cols="auto">
                  <v-btn icon class="text-transform-none" color="primary">
                    <v-icon v-text="'mdi-arrow-right-bold-outline'"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </template>
        <v-col v-else cols="12">
          <no-data
            first-text="No integrations connected to this app"
            :second-text="errorMessage"
          ></no-data>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-for="(item, index) in integrationsList"
      :key="index"
      class="py-0"
      cols="12"
    >
      <v-card outlined class="pa-3">
        <v-row align="center">
          <v-col class="">
            <v-row align="center">
              <v-col cols="auto">
                <v-img
                  class="mb-2"
                  height="40"
                  contain
                  width="40"
                  v-if="item?.integration?.image"
                  :src="`/media/integrations/${item?.integration?.image}`"
                ></v-img>
              </v-col>
              <v-col>
                <div>
                  {{ item?.integration?.name }}
                  <v-btn @click.stop="showIntegrationHelp(item)" icon small>
                    <v-icon small v-text="'mdi-help-circle-outline'"></v-icon>
                  </v-btn>
                </div>
                <div v-if="item.status !== 2" class="cursor-pointer">
                  <v-icon
                    :color="item.connected ? 'success' : 'error'"
                    v-text="'mdi-circle-medium'"
                  ></v-icon>
                  {{ item.connected ? "Connected" : "Connect" }}
                </div>
                <div v-if="item.status === 2">
                  <span
                    @click="notifyWhenAvailable(item)"
                    class="cursor-pointer text-decoration-underline"
                  >
                    <v-icon color="info" v-text="'mdi-circle-medium'"></v-icon>
                    Vote
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="item.status !== 2 && !item.connected"
              @click.stop="
                $router.push({
                  name: 'integration-source',
                  params: { integration_id: item?.integration?.id },
                })
              "
              class="text-transform-none"
              color="primary"
              :outlined="$vuetify.theme.dark"
            >
              Connect
            </v-btn>
            <v-chip v-if="item?.source?.name" class="mr-1">
              {{ item?.source?.name || "Configure" }}
            </v-chip>
            <v-chip v-if="item?.name" class="mr-1">
              {{ item?.name }}
            </v-chip>
            <v-btn
              v-if="item.status !== 2 && item.connected"
              class="ml-2"
              @click.stop="openRemoveModal(item)"
              icon
            >
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12">
      <div class="mb-8 darkGrey--text">
        <v-icon color="grey" left small>mdi-help-circle</v-icon>
        <span>We missed an integration that you need?</span>
        <span
          class="ml-1 primary--text text-decoration-underline cursor-pointer"
          @click="openIntercomChat(contactMessage)"
          >Let us know!</span
        >
      </div>
    </v-col>
    <Modal v-model="showUninstallModal" @close="showUninstallModal = false">
      <template #card>
        <v-card>
          <v-card-title>
            Remove {{ selectedIntegration?.integration?.name }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="font-16">
              <v-col cols="12">
                Are you sure you want to disconnect
                {{ selectedIntegration?.integration?.name }} from this app?
                <div class="my-5">
                  <div class="my-2">Integration information</div>
                  <ul class="mb-2">
                    <li>
                      <b>Workspace: </b>{{ selectedIntegration?.source?.name }}
                    </li>
                    <li><b>Channel: </b>{{ selectedIntegration?.name }}</li>
                  </ul>
                </div>
                <div class="my-5">
                  <i>You can always connect it again.</i>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="showUninstallModal = false"
              text
              class="text-transform-none"
              :disabled="loadingConfirm"
              color="primary"
            >
              Cancel
            </v-btn>
            <v-btn
              :outlined="$vuetify.theme.dark"
              class="text-transform-none"
              color="error"
              :loading="loadingConfirm"
              @click="confirmUnistall"
            >
              Remove
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </Modal>

    <Modal
      closeable
      v-model="integrationHelpModal"
      @close="integrationHelpModal = false"
    >
      <template #card>
        <v-card class="pa-3">
          <v-card-text>
            <v-row>
              <v-col
                class="bodyFont"
                v-html="integrationHelpDescription"
                cols="12"
              >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </Modal>
  </v-row>
</template>
<script>
import {
  GET_APP_INTEGRATION_LIST,
  GET_INTEGRATION_LIST,
} from "@/store/team/integrations.module.js";
import ApiService from "@/core/services/api.service.js";
export default {
  data() {
    return {
      loading: false,
      integrationsList: [],
      errorMessage: null,
      teamIntegrationList: [],
      showUninstallModal: false,
      selectedChannel: "",
      selectedIntegration: null,
      selectedWorkspace: "",
      contactMessage: {
        message:
          "Hello, I would like to suggest a missing integration for SDK:",
        subject: "Suggesting an integration for SDK",
      },
      loadingConfirm: false,
      channelList: [
        {
          text: "Help",
          value: "help",
        },
        {
          text: "General",
          value: "general",
        },
      ],
      workspaceList: [
        {
          text: "bdipesh",
          value: "bdipesh",
        },
        {
          text: "Team",
          value: "team",
        },
      ],
      showConfigureModal: false,
      integrationHelpDescription: "",
      integrationHelpModal: false,
    };
  },
  created() {
    if (this.$route.params.app_id) {
      this.getAppIntegrationsList();
    } else {
      this.getTeamIntegrationList();
    }
  },
  methods: {
    getAppIntegrationsList() {
      this.errorMessage = null;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_APP_INTEGRATION_LIST, {
          app_id: this.$route.params.app_id,
        })
        .then((response) => {
          this.integrationsList = response;
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.errorMessage = err.message;
          this.loading = false;
        });
    },
    getTeamIntegrationList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_INTEGRATION_LIST)
        .then((response) => {
          this.teamIntegrationList = response;
          this.loading = false;
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    goToDetailPage(detail) {
      if (detail.status !== 2) {
        this.$router.push({
          name: "integration-source",
          params: { integration_id: detail.id },
        });
      } else {
        this.notifyWhenAvailable(detail);
      }
    },
    openRemoveModal(detail) {
      this.selectedIntegration = detail;
      this.showUninstallModal = true;
    },
    confirmUnistall() {
      if (this.loadingConfirm) return;
      this.loadingConfirm = true;
      ApiService.delete(
        `portal${this.apiRoutes.portal.integrations.sourceSubApp.delete(
          this.selectedIntegration?.integration?.id,
          this.selectedIntegration?.source?.id,
          this.selectedIntegration.sub_id
        )}`,
        {
          app_id: this.$route.params.app_id,
        }
      )
        .then(({ data }) => {
          this.loadingConfirm = false;
          this.selectedIntegration = null;
          this.showUninstallModal = false;
          this.getAppIntegrationsList();
          this.notifyUserMessage({
            message: data.message,
          });
          this.loadingConfirm = false;
        })
        .catch((err) => {
          this.loadingConfirm = false;
          this.notifyErrorMessage(err.message);
        });
    },
    showIntegrationHelp(detail) {
      if (this.$route.params.app_id) {
        this.integrationHelpDescription = detail?.integration?.description;
      } else {
        this.integrationHelpDescription = detail?.description;
      }

      this.integrationHelpModal = true;
    },
    notifyWhenAvailable(detail) {
      let dataToPost = {
        app_id: this.$route.params.app_id || "",
        feature: "Integrations",
        meta: {
          name: detail?.integration?.name || detail?.name,
          id: detail?.integration?.id || detail?.id,
        },
        message: "",
      };

      return this.$coreApi
        .post(this.apiRoutes.core.session.notifyWhenAvailable, dataToPost)
        .then(({ data }) => {
          this.notifyUserMessage({ message: data.message });
        })
        .catch((error) => {
          this.notifyErrorMessage(error?.message);
        })
        .finally(() => {
          this.busy = false;
        });
    },
    configureIntegration(detail) {
      this.selectedIntegration = detail;
      this.showConfigureModal = true;
    },
    openConfigure() {
      this.showConfigureModal = true;
    },
  },
};
</script>
